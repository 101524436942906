<template>
    <div class="main">
        <p><strong>“{{ bundleName }}”隐私政策</strong></p>
        <p>更新日期：2022年9月28日</p>
        <p>生效日期：2022年9月28日</p>

        <br><p><strong>提示条款</strong></p>
        <p><strong>“{{ bundleName }}”</strong>（以下简称“我们”）深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠，我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则，目的明确原则，选择同意原则，最少必要原则，确保安全原则，主体参与原则，公开透明原则等等。同时我们承诺，我们将依据《中华人民共和国个人信息保护法》《中华人民共和国网络安全法》、《信息安全技术个人信息安全规范》（GB/T 35273）以及其他相关法律法规和技术规范，恪守上述原则，采取相应的安全保护措施来收集、使用、存储、共享、披露、处理和保护您的个人信息。鉴此，我们制定本《隐私政策》（下称“本政策 /本隐私政策”）并提醒您：</p>
        <p>本政策适用于<strong>“{{ bundleName }}”</strong>软件及相关服务。</p>
        <p>本产品及相关服务中某些具体的模块或功能由第三方提供，如第三方登录分享。如果为了向您提供服务而需要将您的信息共享至第三方，我们将要求第三方按照我们的要求、本政策以及其他任何相关的保密和安全措施来收集、使用、存储、披露和处理您的个人信息并且严格遵守相关法律法规与监管要求。<strong>需要特别说明的是，本政策不适用于第三方向您提供的服务，也不适用于我们平台中已另行独立设置法律法规声明及隐私政策的产品或服务。</strong>例如，当第三方依托我们平台向您提供服务时，我们会向您提示该服务由第三方提供，您向第三方提供的个人信息不适用于本隐私政策。</p>
        <p><strong>在使用“{{ bundleName }}”软件及相关服务前，请您务必仔细阅读并理解本政策，特别是以粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后使用相关产品或服务。您可以通过本隐私政策所列的途径访问、更正或删除您的个人信息。</strong>如对本政策内容有任何疑问、意见或建议，您可通过“{{ bundleName }}”提供的各种联系方式与我们联系。</p>
        <p><strong>如果您是未满18周岁的未成年人，请在监护人的陪同下阅读并理解本协议及</strong>相关服务相关的协议和规则（包括但不限于《“{{ bundleName }}”用户协议》）。特别地，如果您是未满14周岁的未成年人，只有在征得您的监护人的同意后，您方可使用“{{ bundleName }}”软件及相关服务。</p>
        <p>下文将帮您详细了解我们如何收集、使用、存储、传输、共享、转让、披露与保护个人信息；帮您了解查询、访问、删除、更正、撤回授权个人信息的方式。其中，有关您个人信息权益的条款重要内容我们已用加粗形式提示，请特别关注：</p>
        <p>一、 我们向您收集的个人信息及其用途</p>
        <p>二、 我们可能需要获取的您的设备权限</p>
        <p>三、 我们如何共享、转让、披露您的个人信息</p>
        <p>四、 我们如何保护您的个人信息</p>
        <p>五、 您如何管理您的个人信息</p>
        <p>六、 我们如何处理未成年人的个人信息</p>
        <p>七、 我们存储您的个人信息的位置和方式</p>
        <p>八、 本隐私政策如何更新</p>
        <p>九、 如何联系我们</p>

        <br><br><p>一、 我们向您收集的个人信息及其用途</p>
        <p><strong>人信息</strong>指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</p>
        <p><strong>个人敏感信息</strong>是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。</p>
        <p><strong>我们在此特别提醒您：</strong></p>
        <p>为给您带来更好的产品和服务体验，我们在持续努力改进我们的产品、服务及技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。如发生上述情形，我们将通过更新本政策、弹窗、页面提示等方式另行向您详细说明，并为您提供自主选择同意的方式，且在征得您明示同意后再开始收集、使用或进行其他相应处理。在此过程中，如果您有任何疑问、意见或建议的，您可通过本政策文末联系方式与我们联系，我们会尽快为您作出解答。</p>
        <p>我们将以下述目的，收集和使用您的个人信息：</p>
        <p>1、 当您注册<strong>“{{ bundleName }}”</strong>帐号时，我们会收集您的<strong>手机号码</strong>，用于为您创建帐号并为您提供帐号登录服务、产品相关服务，您可以通过<strong>手机号码及验证码匹配结果</strong>进行身份验证。若您不注册帐号，您可能无法正常使用我们的服务。</p>
        <p>2、 如果您注册了<strong>“{{ bundleName }}”</strong>帐号并根据自身需求设置或填写了<strong>昵称、头像、生日</strong>等个人资料信息，我们将会收集这些信息用于识别您的身份。</p>
        <p>3、 当您使用<strong>“{{ bundleName }}”</strong>拍摄编辑图片时，为了向您提供该服务，我们会在获得您的明示同意后，访问您的<strong>相机权限</strong>。上述信息属于敏感信息，拒绝提供该信息可能影响您正常使用{{ bundleName }}服务。</p>
        <p>4、当您使用<strong>拍摄功能</strong>时，为了提供更好的用户体验与展示效果，我们可能需要获取您的面部特征信息用于美颜，我们不会将这些信息回传服务器，也不会存储这些信息，更不会将其用于除此之外的任何其他目的。</p>
        <p>5、 当您使用发布动态等功能时，您发布的文字、照片、表情等信息会存储在我们的服务器中，因为存储是实现这一功能所必需的。我们会以加密的方式存储，您也可以随时删除这些信息。除非经您自主选择或遵从相关法律法规要求或服务协议的约定，我们不会对外提供上述信息，或者将其用于该功能以外的其他用途。当您使用发布动态等功能时， <strong>“{{ bundleName }}”将会展示您的昵称、头像、发布时间、发布内容（文字、照片、表情），其他用户可通过查看动态页面并通过点击您的头像进入您的个人页面，查看您的个人资料页以及您公开的所有发布内容。请您基于前述公开范围谨慎考虑在“{{ bundleName }}”发布的内容。</strong></p>
        <p>6、我们会根据您在安装、访问及使用<strong>“{{ bundleName }}”</strong>软件及相关服务的过程中或“{{ bundleName }}“软件在后台运行时授予的具体权限收集您所使用的<strong>设备相关信息（例如设备型号、操作系统版本、设备配置、IP地址、设备标识（IMEI/Android ID/OAID/IDFA/IDFV/MEID/UDID/SIM卡IMSI信息/ICCID等）、设备序列号、MAC地址、设备网络信息(运营商信息/WIFI信息/SSID/BSSID)、设备环境、软件列表、运行中进程等软硬件特征信息），设备所在位置相关信息（包括WLAN接入点、基站等传感器信息）。</strong>用于保障软件与服务的安全运行、运营的质量及效率。</p>
        <p>7、 我们会收集您使用<strong>“{{ bundleName }}”</strong>软件及相关服务的<strong>网络日志信息（您使用“{{ bundleName }}”软件及相关服务的日期、时间等），</strong>作为网络日志保存，用于识别帐号异常状态及满足相关法律法规的要求。</p>
        <p>8、 我们可能会收集、使用为实现安全保障功能的必要信息，并可能会整合或使用您的<strong>帐号信息、设备信息、日志信息以及我们关联方、合作方在获得您授权或依法可以共享的信息，</strong>用于提高您使用我们软件及相关产品服务的安全性、保护您或其他用户或公众的人身财产安全免遭侵害，以及更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或“{{ bundleName }}”相关协议规则的情况。并用于综合判断您账户的风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、分析、处置措施。</p>
        <p>9、我们可能会在您与<strong>我们联系时保存您与客服之间的通信/通话记录和内容或您留下的联系方式等信息，</strong>以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</p>
        <p>10、 征得授权同意的例外</p>
        <p><strong>根据相关法律法规规定，我们在以下情形中收集、使用您的个人信息无需征得您的授权同意：</strong></p>
        <p>（1） 与我们履行法律法规规定的义务相关的；</p>
        <p>（2） 与国家安全、国防安全直接相关的；</p>
        <p>（3） 与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>（4） 与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
        <p>（5） 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人授权同意的；</p>
        <p>（6） 所收集的个人信息是您自行向社会公众公开的；</p>
        <p>（7） 根据您要求签订和履行合同所必需的；</p>
        <p>（8） 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
        <p>（9） 维护所提供产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
        <p>（10） 为开展合法的新闻报道所必需的；出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
        <p>（11） 法律法规规定的其他情形。</p>
        <p><strong>如我们停止运营“{{ bundleName }}”软件及相关服务，我们将立即停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并按相关法律法规的规定对所持有的个人信息进行删除或匿名化处理。</strong></p>
        <p><strong>特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策使用、处理与保护。</strong></p>

        <br><p>二、我们可能需要获取的您的设备权限</p>
        <p>您理解并同意，我们可能基于下述原因，需要您在您的设备中授权以实现这些权限所涉及信息的收集和使用。<strong>您可在您的设备设置中逐项查看下述权限的状态，并可自行决定这些权限的开启或关闭。</strong>请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</p>
        <p>1、 我们需要获取访问您的<strong>相机的授权</strong>，用于为您提供您自主自愿拍摄的照片用于作为您的帐号头像或用于发布动态或发布相册功能的素材。</p>
        <p>2、 我们需要获取访问您的<strong>相册的授权</strong>，用于为您提供您选择相册里的一张照片用于作为您的帐号头像。或用于发布动态或发布相册功能的素材。</p>
        <p>3、 我们需要获取您的<strong>手机通知权限</strong>，用于我们向您推送相关服务信息。</p>
        <p>4、我们需要获取访问您的<strong>手机通讯录权限</strong>，在将通讯录中的信息进行高强度加密算法处理后，用于向您推荐通讯录中的好友。如您拒绝授权，将无法在{{ bundleName }}内添加通讯录好友，但不影响您使用{{ bundleName }}的其他功能。</p>

        <br><p>三、 我们如何共享、转让及披露您的个人信息</p>
        <p>（一）共享</p>
        <p>我们不会与我们以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</p>
        <p>1、 在获取明确同意的情况下共享：获得您的明确同意后，我们会在您的授权范围内与其他方共享您的个人信息。</p>
        <p>2、 在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或根据行政、司法机关依法提出的要求，对外共享您的个人信息。</p>
        <p>3、 <strong>在法律要求或允许的范围内，为了保护公司及其他用户或社会公众的利益、财产或安全免遭损害而有必要提供您的个人信息给第三方。</strong></p>
        <p>4、 <strong>与关联公司共享：</strong>为便于我们基于平台帐号向您提供产品或服务，识别我们帐号异常，您的个人信息可能会与我们的关联公司或其指定的服务提供商共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。我们将要求关联公司按照我们的要求、本政策以及其他任何相关的保密和安全措施来收集、使用、存储、披露和处理您的个人信息。</p>
        <p>5、 <strong>与授权合作伙伴共享：为了向您提供更完善、优质的产品和服务，我们可能委托授权合作伙伴或使用第三方SDK相关技术为您提供服务或代表我们履行职能。</strong>我们仅会出于本隐私政策声明的合法、正当、必要、特定、明确的目的共享您的信息，合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。我们将要求授权合作伙伴按照我们的要求、本政策以及其他任何相关的保密和安全措施来收集、使用、存储、披露和处理您的个人信息。具体如下：</p>
        <table>
            <tr>
                <th>第三方公司名称</th>
                <th>产品/类型</th>
                <th>使用目的</th>
                <th>共享信息名称</th>
                <th>官网链接</th>
            </tr>
            <tr>
                <td>友盟同欣(北京)科技有限公司</td>
                <td>移动统计 SDK</td>
                <td>适用于App统计分析场景。稳定性功能已升级为独立的应用性能监控平台，新增用户细查、崩溃回调等能力，若同时需要统计和崩溃捕获能力</td>
                <td>设备标识信息（Android如IMEI、Android ID、OAID，iOS如IDFA、IDFV），设备类型，设备版本，系统版本，地理位置信息，网络设备制造商，IP地址，网络模式，运行中进程</td>
                <td>https://www.umeng.com/page/policy</td>
            </tr>
            <tr>
                <td>北京阿里云科技有限公司</td>
                <td>内容安全风控服务</td>
                <td>内容审核</td>
                <td>用户设置的昵称、个性签名以及公开发表的动态文案</td>
                <td>http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=a1zaa.8161610.0.0.25d03b47hGIkZF</td>
            </tr>
            <tr>
                <td>腾讯云科技有限公司</td>
                <td>IM SDK</td>
                <td>获取即时通讯能力</td>
                <td>网络连接状态、设备型号、系统版本</td>
                <td>https://cloud.tencent.com/document/product/269/58094</td>
            </tr>
            <tr>
                <td>腾讯云科技有限公司</td>
                <td>音视频通话 SDK</td>
                <td>获取音视频通话能力</td>
                <td>Wi-Fi 状态、系统属性、设备型号、操作系统、IP 地址、相机、录音、传感器信息</td>
                <td>https://cloud.tencent.com/document/product/1449/80194</td>
            </tr>
            <tr>
                <td>杭州相芯科技有限公司</td>
                <td>美颜美型 SDK</td>
                <td>美颜美型</td>
                <td>手机型号、唯一设备号、APP包名、IP地址</td>
                <td>https://www.faceunity.com/policy.html</td>
            </tr>
            <tr>
                <td>小米科技有限公司</td>
                <td>小米推送SDK</td>
                <td>消息推送</td>
                <td>设备标识(IMEI、MEID、OAID、加密的Android ID、SN)、推送消息内容、设备厂商、型号、归属地、运营商名称、通知栏设置、访问网络状态、写入设备存储</td>
                <td>https://dev.mi.com/console/doc/detail?pId=1822</td>
            </tr>
            <tr>
                <td>华为终端有限公司</td>
                <td>华为推送SDK</td>
                <td>消息推送</td>
                <td>应用基本信息（应用版本号/应用包名)、应用内设备标识符(AAID/Push Token)、设备的硬件信息、系统基本信息和系统设置信息</td>
                <td>https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177</td>
            </tr>
            <tr>
                <td>维沃移动通信有限公司</td>
                <td>Vivo 推送 SDK</td>
                <td>消息推送</td>
                <td>应用安装列表、设备标识符（如IMEI、ICCID 、EMMCID/UFSID 、OAID/VAID/AAID、SN码 和 IMSI、Android ID和VIVO安卓设备的移动网号）、MAC地址</td>
                <td>https://www.vivo.com.cn/about-vivo/privacy-policy</td>
            </tr>
            <tr>
                <td>广东欢太科技有限公司</td>
                <td>OPPO 推送 SDK</td>
                <td>消息推送</td>
                <td>设备标识（IMEI或OAID、SN、IMSI、Android ID、GAID）、应用信息（APP包名及版本号，运行状态）、网络信息（IP或域名连接结果，当前网络类型）</td>
                <td>https://open.oppomobile.com/wiki/doc#id=10288</td>
            </tr>
        </table>
        <p>（二）转让</p>
        <p>我们不会将您的个人信息转让给任何第三方，但以下情况除外：</p>
        <p>1、 在获取明确同意的情况下转让：获得您的明确同意后，我们会在您的授权范围内向其他方转让您的个人信息。</p>
        <p>2、 在涉及合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织或个人继续受本政策的约束，否则我们将要求该公司、组织或个人重新向您征求授权同意。</p>
        <p>（三）披露</p>
        <p>我们不会向任何第三方披露您的个人信息，但在以下情况下，我们会采取符合法律法规和业界标准的安全防护措施的前提下，公开披露您的个人信息：</p>
        <p>1、 您自主自愿选择披露或另行授权我们披露给第三方时，我们会将您同意或选择披露的个人信息披露给您指定的第三方。</p>
        <p>2、 <strong>基于法律法规、法律程序、诉讼或政府部门强制性要求，或严重违反公司相关协议及规则的情况，或为保护用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或在征得您同意的情况下披露您的个人信息。</strong>但我们保证，在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件。</p>
        <p>3、 需要对违规帐号、欺诈行为等进行处罚公告时，我们可能公开披露该等帐号相关的信息。</p>
        <p>4、 您公开发布的动态、图片、视频等内容将会在您的个人主页中展示。</p>
        <p>（四）以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
        <p>1、 与我们履行法律法规规定的义务相关的；</p>
        <p>2、 与国家安全、国防安全直接相关的；</p>
        <p>3、 与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>4、 与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
        <p>5、 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人授权同意的；</p>
        <p>6、 您自行向社会公众公开的个人信息；</p>
        <p>7、 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
        <p>8、 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
        <p>9、 法律法规规定的其他情形。</p>
        <p>请知悉，根据《中华人民共和国个人信息保护法》《中华人民共和国网络安全法》等相关法律法规的规定，若我们对个人信息<strong>采用技术措施和/或其他必要措施进行去标识化处理，且确保此类信息相关数据的接收方无法重新识别特定个人且不能复原，则此类处理后数据的对外共享、转让、公开披露、保存及处理无需另行向您通知并征得您的同意。</strong></p>

        <br><p>四、 我们如何保护您的个人信息</p>
        <p>1、 我们努力为用户的个人信息提供安全保障，以防止用户个人信息的非法使用、未经授权的访问和披露、泄漏、损坏和丢失。我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们会使用加密技术（例如，SSL）、匿名化处理、加密存储等手段来保护您的个人信息；建立受信赖的保护机制防止个人信息遭到恶意攻击；建立访问控制机制，遵守最小权限原则，确保只有授权人员才可访问个人信息；开展安全和个人信息保护培训课程，加强员工对于保护个人信息的认识。</p>
        <p>2、 我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从管理机构、组织建设、制度体系、产品技术等方面多维度提升整个系统的安全性。我们建立专门的管理制度、流程和组织以保障个人信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</p>
        <p>3、 我们会采取合理可行的措施，尽力避免收集无关的个人信息。如您发现自己的个人信息尤其是您的帐号或密码发生泄露，请您通过本隐私政策中【九、如何联系我们】部分列明的联系方式立即联络我们，以便我们根据您的申请采取相应措施。</p>
        <p>4、 尽管已经采取了上述合理措施，并已经遵守了相关法律规定要求的标准，但请您理解，互联网环境并非百分之百安全，我们将尽力确保您提供给我们的个人信息的安全性，同时建议您妥善保护您的个人信息（如您的帐号名、密码等），在非必要情形下不向他人提供。</p>
        <p>5、 当您离开我们的平台、本产品及相关服务，访问或使用由我们以外的第三方运营的产品或服务时，我们将没有能力和直接义务保护您向该等第三方产品或服务提供的任何个人信息，无论您访问或使用上述产品或服务是否基于我们的链接或引导。</p>
        <p>请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息，请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。</p>

        <br><p>五、 您如何管理您的个人信息</p>
        <p>您有权要求阅览、查询、更正、修改、删除您的个人信息、注销帐号、撤回已同意的授权，您可以通过以下方式访问及管理您的个人信息：</p>
        <p>1、访问您的个人信息</p>
        <p>您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：</p>
        <p>帐号信息——如果您希望访问或编辑您的帐号中的个人资料信息，如头像和昵称，您可以通过点击编辑资料来执行此类操作。</p>
        <p>如果您无法通过上述路径访问该等个人信息，您可以随时通过“{{ bundleName }}”客服或发送电子邮件至【anhao1108@163.com】与我们取得联系。我们将在15天内回复您的访问请求。</p>
        <p>对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条“6响应您的上述请求”中的相关安排向您提供。</p>
        <p>2、 更正或补充您的个人信息</p>
        <p>当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过本条“1访问您的个人信息”中列明的方式提出更正或补充申请。</p>
        <p>3、删除您的个人信息</p>
        <p>您可以通过本条“1访问您的个人信息”中列明的方式删除您的部分个人信息。</p>
        <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
        <p>（1） 如果我们处理个人信息的行为违反法律法规；</p>
        <p>（2） 如果我们收集、使用您的个人信息，却未征得您的明确同意；</p>
        <p>（3） 如果我们处理个人信息的行为严重违反了与您的约定；</p>
        <p>（4） 如果您不再使用我们的产品或服务，或您注销了帐号；</p>
        <p>（5） 如果我们不再为您提供产品或服务。</p>
        <p>若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。</p>
        <p>当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>
        <p>4、改变您授权同意的范围</p>
        <p>每个业务功能需要一些基本的个人信息才能得以完成（见本隐私政策“第一条”）。您可以通过设备本身操作系统变更或撤回相机、相册、消息通知等权限，我们将根据您变更或撤回权限的设置进行个人信息的收集，如您撤回授权我们将不再收集与这些权限相关的信息。请您理解，如果特定授权或同意对应的个人信息是我们提供特定功能或服务所必需的，因您的授权撤回，我们可能无法继续为您提供撤回同意或授权所对应的特定功能和服务。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</p>
        <p>5、 帐号注销</p>
        <p>您可以在个人页面中依次点击“主界面右上角我的头像-设置-注销账号”提交帐号注销申请，我们将在验证您的身份后为您注销当前帐号。<strong>您的帐号注销成功后，我们将停止为您提供产品或服务，并根据相关法律法规的要求删除或匿名化处理您的个人信息，但法律法规另有规定的除外。</strong></p>
        <p>6、 响应您的上述请求</p>
        <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>
        <p>我们将在15天内做出答复。如您不满意，还可以通过发送电子邮件至【anhao1108@163.com】发起投诉。</p>
        <p>对于那些无端重复、超出合理限度、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</p>
        <p>在以下情形中，按照法律法规要求，我们将无法同意您的请求：</p>
        <p>（1） 与我们履行法律法规规定的义务相关的；</p>
        <p>（2） 与国家安全、国防安全直接相关的；</p>
        <p>（3） 与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>（4） 与刑事侦查、起诉、审判和执行判决等直接相关的；</p>
        <p>（5） 我们有充分证据表明您存在主观恶意或滥用权利的；</p>
        <p>（6） 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</p>
        <p>（7） 响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
        <p>（8） 涉及商业秘密的。</p>

        <br><p>六、 我们如何处理未成年人的个人信息</p>
        <p><strong>如果没有父母或监护人的同意，未成年人不应创建自己的用户账户。</strong></p>
        <p>1、 我们特别重视未成年人的个人信息的保护，我们无意也不试图收集未满18周岁未成年人的信息。如果您为未满18周岁的未成年人，在注册、使用我们的产品和/或服务前，请您务必在父母或其他监护人的监护和指导下阅读并同意本隐私政策，并确保已征得您的监护人明确同意后，在监护人的帮助下完成注册、使用或向我们提供您的个人信息。</p>
        <p>2、 若您使用我们的服务，表示您及您的监护人已理解本政策，并同意按照本政策规定的方式对您的个人信息进行收集和使用。对于经监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或其他监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</p>
        <p>3、 如您在未得到监护人的同意下向我们提供了个人信息，或您的监护人虽同意您按照本政策使用我们的服务或向我们提供个人信息但对您使用我们的产品和/或服务或其向我们提供的用户信息有任何疑问时，您的监护人可以立即终止使用我们的服务并及时通知我们以移除此类信息，我们则会设法尽快删除相关信息。</p>

        <br><p>七、 我们存储您的个人信息的位置和方式</p>
        <p>我们按本政策收集的您的个人信息，会按照法律法规的规定，将中华人民共和国境内（以下简称“中国”）运营过程中收集和产生的用户个人信息存储在中国境内。</p>
        <p>我们按本政策收集的您的个人信息，以加密的方式存储。</p>
        <p>我们按本政策收集的您的个人信息，原则上，我们仅在为您提供服务所必需的最短时间内保留您的个人信息，您自行删除、注销帐号或超出必要期限的个人信息我们会立即进行删除或匿名化处理，除非相关法律法规政策另有规定，或者司法机关、相关政府部门另有要求。如按相关法律法规政策的规定或司法机关、相关行政部门的要求我们必须延长保存您的个人信息的时间时，在延长保存期间内，我们会以加密形式安全地存储您的个人信息并进行隔离，直至按相关法律法规或司法机关的要求可以删除或匿名化处理。</p>

        <br><p>八、 本隐私政策如何更新</p>
        <p>我们的隐私政策可能会根据业务的调整、法律法规或政策的变化等进行不定期的修改或变更，我们会在本产品APP内发布更新后的隐私政策。</p>
        <p>未经您明确同意，我们不会消减您按照本隐私政策所应享有的权利。对于重大变更，我们会提供显著的通知（包括通过“{{ bundleName }}”公示的方式进行通知甚至在APP中向您提供弹窗提示）。如您继续使用我们的服务，即表示您同意接受更新后的隐私政策约束；如您不同意更新后的隐私政策，您有权对您的个人信息进行修改、删除，注销您的帐号，或者立即停止使用我们的产品及相关服务或其中的特定业务功能。</p>
        <p>本政策所指的重大变更包括但不限于：</p>
        <p>1、 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
        <p>2、 我们在控制权等方面发生重大变化。如业务调整、并购重组等引起的所有者变更等；</p>
        <p>3、 个人信息共享、转让或公开披露的主要对象发生变化；</p>
        <p>4、 您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
        <p>5、 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
        <p>6、 个人信息安全影响评估报告表明存在高风险时。</p>

        <br><p>九、 如何联系我们</p>
        <p>1、 您可以通过“{{ bundleName }}”客服或发送电子邮件至【anhao1108@163.com】与我们联系，我们将在15天内回复您的请求。</p>
        <p>2、 如发现个人信息可能被泄露，您可以发邮件至【anhao1108@163.com】投诉举报。</p>
        <p>3、 我们的地址为江西省赣州市赣县区梅林镇孝本路1号滨江花城四期2栋602室，个人信息保护相关负责人的联系方式为【anhao1108@163.com】。</p>
        <p>如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，且双方未能协商解决的，您还可以通过向本协议签订地（中华人民共和国江西省赣州市）有管辖权的法院提起诉讼来寻求解决方案。</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            bundle: '',
            bundleName: '喂喂',
        }
    },
    mounted() {
        this.bundle = this.$route.params['bundle']
        if (this.bundle == 'anhao') {
            this.bundleName = '暗号'
        }
        if (this.bundle == 'jianliao') {
            this.bundleName = '简聊'
        }
        if (this.bundle == 'weiwei') {
            this.bundleName = '喂喂'
        }
        if (this.bundle == 'mangqu') {
            this.bundleName = '盲趣'
        }
    }
}
</script>

<style scoped>
.main {
    /* width: 375px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

strong {
    font-weight: bold;
    font-size: 10px;
}

p {
    word-break:break-all;
    /* text-indent:4em; */
    font-size: 10px;
    margin-top: 10px;
}

table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px;
}

table td, table th {
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
}

table th {
    width: 20%;
    vertical-align: top;
}

tbody {
    border-bottom: 1px solid #000;
}

tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}

tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

table td, table td a {
    table-layout: fixed;
    word-break: break-all;
    word-wrap: break-word;
}

</style>